import request from '@/auth/jwt/request'

/**
 * @description 项目管理 -- 项目管理列表
 * @param {Object} param params {Object} 传值参数
 */
export const imageListApi = params => { return request('project-pic/list', 'get', params) }

/**
 * @description 项目管理 -- 增加修改项目
 * id = 0 新增
 * @param {Object} param params {Object} 传值参数
 */
export const imageSaveApi = data => { return request('project-pic/save', 'post', {}, data) }

/**
 * @description 话术管理 -- 话术分类下拉
 * @param {Object} param params {Object} 传值参数
 */
export const imageProjetSelectApi = () => { return request('project/select', 'get', null) }


/**
 * @description 项目管理 -- 项目状态管理
 * @param {Object} param params {Object} 传值参数
 */
export const imageStatusApi = params => { return request('project-pic/status', 'get', params) }

/**
 * @description 项目管理 -- 项目详情
 * @param {Object} param params {Object} 传值参数
 */
export const imageInfoApi = params => { return request('project-pic/info', 'get', params) }
/**
 * @description 项目管理 -- 删除项目
 * { id = x }
 */
export const imageDeleteApi = data => { return request('project-pic/delete', 'delete', data) }